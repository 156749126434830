import React from "react";
import CarouselCustom from "../../components/CarouselCustom";
import ParticipateInstructions from "../../components/ParticipateInstructions";
import { Row, Col, Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { getBrandListAction } from "../../redux/actions";
const Home = (props) => {
	const user = useSelector(({ user }) => user);
	let [brandList, setBrandList] = React.useState([]);
	React.useEffect(() => {
		if (user.accessToken) {
			loadBrandList();
		}
	}, []);
	const loadBrandList = () => {
		props.setLoading(true);
		getBrandListAction()
			.then((response) => {
				if (response.httpCode === 200 && response.data) {
					if (response.data) {
						//let dt=response.data.sort((a,b) =>a.brandName.toLowerCase() > b.brandName.toLowerCase() ? 1 : -1)
						let dt = response.data.sort((a, b) => (a.brandName.toLowerCase() > b.brandName.toLowerCase() ? 1 : -1));
						let fData = [];
						for (let i in dt) {
							let ind = fData.findIndex((v) => v.brandName === dt[i].brandName);
							if (ind < 0) {
								fData.push(dt[i]);
							}
						}
						setBrandList(fData);
					}
				}
				props.setLoading(false);
			})
			.catch((e) => {
				props.setLoading(false);
			});
	};
	if (user.accessToken) {
		return (
			<>
				{/* start welcome other page */}
				<div id="home">
					<a href="/upload-receipt">
						<img className="d-block w-100 banner-img desktop-hide" src="/public/assets/images/banner/Desktop 4_PGP_AssetRequest_LifestyleImages_1920x600.png" alt="Submit Here Banner" />
						<img className="d-block w-100 mobile-hide banner-img" src="/public/assets/images/banner/Mobile 3_PGP_AssetRequest_LifestyleImages_450x500.png" alt="Submit Here Banner" />
					</a>
				</div>

				{/* <div className="row justify-content-md-center section-content" id="how-it-works">
					<Col md={12}>
						<h1 className=" mt-5 mb-3 section-heading text-center">
							Welcome back, <span>{user.name ? user.name : user.username}</span>!
						</h1>
						<h2 className="text-center">How it works: 3 easy steps</h2>
					</Col>
					<Col md={9}>
						<Row className="justify-content-md-center mt-4">
							<Col md={4} xs={12} className="d-flex">
								<div className="w-100">
									<div className="text-center my-4">
										<img src="/public/assets/images/welcome-page/Sign-up.png" alt="Sell & Earn" />
									</div>
									<h2 className="text-center upper-case-text">
										<Trans>Sign Up</Trans>
									</h2>
									<p className="text-center">View current promotions</p>
								</div>
							</Col>
							<Col md={4} xs={12} className="d-flex">
								<div className="w-100">
									<div className="text-center my-4">
										<img src="/public/assets/images/welcome-page/003-receipt.png" alt="Sell & Earn" />
									</div>
									<h2 className="text-center">
										<Trans>lang_key_sell</Trans> & <Trans>lang_key_earn</Trans>
									</h2>
									<p className="text-center">Sell P&C Professional’s trusted brands & earn rewards</p>
								</div>
							</Col>
							<Col md={4} xs={12} className="d-flex">
								<div className="w-100">
									<div className="text-center my-4">
										<img src="/public/assets/images/welcome-page/001-dollar-symbol.png" alt="Redeem" />
									</div>
									<h2 className="text-center upper-case-text">
										<Trans>Redeem</Trans>
									</h2>
									<p className="text-center">Points for gift cards</p>
								</div>
							</Col>
						</Row>
					</Col>

					<Col md={7}>
						<Row className="">
							<div className="justify-content-left col-12">
								<Col md={12}>
									<p className="text-center my-5">Here are the January to March P&G Professional Partner Rewards. Earn points for incremental cases sold of the below brands!</p>
								</Col>
							</div>
							{brandList.map((v, i) => {
								return (
									<Col md={6} className="my-3 d-flex ">
										<Card>
											<div className="text-center card-p card-body d-flex flex-column">
												<h4 className="text-center">
													<strong>{v.brandName}</strong>
												</h4>
												<p className="text-center">{`${v.points > 1 ? `${v.points} points` : `${v.points} point`} ${v.description}`}</p>
												<div className="text-center">
													<img className="col-8" alt={v.brandName} src={v.imageUrl} />
												</div>
											</div>
										</Card>
									</Col>
								);
							})}
						</Row>
					</Col>
					<Col sm={12}>
						<div className="py-5 text-center">
							<a
								className="btn-bold"
								target="_blank"
								href="https://pgone.sharepoint.com/sites/DSRIncentivePilot/Shared%20Documents/Forms/AllItems.aspx?csf=1&web=1&e=9lf5Y9&cid=3d9299cd%2D68db%2D4fb1%2Dbe1c%2Dfef9e3e75ea1&FolderCTID=0x012000D0AD86018915D8459C5EEF746AECCFBF&id=%2Fsites%2FDSRIncentivePilot%2FShared%20Documents%2FGeneral%2FAssets%20for%203TL%2FSelling%20Materials&viewid=8d9eff26%2D50c1%2D4e64%2Da8ab%2Deae6007cf034%20-%20Connect%20your%20OneDrive%20account">
								Sales Assets
							</a>
						</div>
					</Col>
				</div> */}
				<div className="row justify-content-md-center section-content" id="how-it-works">
					<p className="text-center">As of April 1, 2023, we have temporarily paused the P&G Professional Rewards Program. To redeem your points visit the Rewards page.</p>
				</div>
				{/* end welcome */}
			</>
		);
	} else {
		return (
			<>
				<CarouselCustom />
				<ParticipateInstructions />
			</>
		);
	}
};

export default IsLoadingHOC(Home, "");
