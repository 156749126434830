import React from "react";
import { Carousel, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { carouselData } from "../data";

const CarouselCustom = () => {
	const user = useSelector(({ user }) => user);
	return (
		<Container className="carouselCustom" fluid>
			<div className="row justify-content-center" id="home">
				<Carousel>
					{carouselData.map((item, index) => {
						return (
							<>
								{!user.accessToken ? (
									// <a href="/register" className="w-100"></a>
									<>
										<div className="text-center banner-notice">As of April 1, 2023, We have temporarily paused the P&G Professional Rewards Program. If you are already registered, you can still login, check your account balance, and redeem rewards for gift cards.</div>
										<img className="d-block w-100 banner-img desktop-hide" src={item.img} alt={item.title} />
										<img className="d-block w-100 mobile-hide banner-img" src={item.mobile_img} alt={item.title} />
									</>
								) : (
									<>
										<img className="d-block w-100  banner-img desktop-hide" src={item.img} alt={item.title} />
										<img className="d-block w-100 mobile-hide banner-img" src={item.mobile_img} alt={item.title} />
									</>
								)}
							</>
						);
					})}
				</Carousel>
			</div>
		</Container>
	);
};

export default CarouselCustom;
