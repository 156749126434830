import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
class ParticipateInstructions extends React.Component {
	render () {
		return (
			<Row className="justify-content-md-center section-content" id="how-it-works">
				<Col md={9} className="mb-5">
					<h1 className="main-heading section-heading text-center">P&G Professional Partner Rewards</h1>
					<p className="text-center">A Distributor Sales Representative Rewards Program</p>
					<p className="text-center">
						Our goal is to help businesses thrive by providing the right cleaning
						solutions to delight their customers and keep them coming back.
					</p>
					{/* <div className="text-center mb-5">
						<a href="/register" className="btn btn-primary"><Trans>lang_key_joinNow</Trans></a>
					</div> */}

				</Col>
				<Col md={9}>
					<Row className="justify-content-md-center">
						<Col md={4} xs={12} className="d-flex">
							<Card className="w-100">
								{/* <div className="text-center my-4">
									<img src="/public/assets/images/welcome-page/Sign-up.png" alt="Sell & Earn" />
								</div> */}
								<h2 className="text-center upper-case-text">
									<Trans>lang_key_sell</Trans>
								</h2>
								<p className="text-center">P&G Professional's portfolio of recognizable brand that are trusted to overcome the toughest professional challenges.</p>
							</Card>
						</Col>
						<Col md={4} xs={12} className="d-flex">
							<Card className="w-100">
								{/* <div className="text-center my-4">
									<img src="/public/assets/images/welcome-page/003-receipt.png" alt="Sell & Earn" />
								</div> */}
								<h2 className="text-center">
									<Trans>lang_key_earn</Trans>
								</h2>
								<p className="text-center">Rewards for growing brand sales.</p>
							</Card>
						</Col>
						<Col md={4} xs={12} className="d-flex">
							<Card className="w-100">
								{/* <div className="text-center my-4">
									<img src="/public/assets/images/welcome-page/001-dollar-symbol.png" alt="Redeem" />
								</div> */}
								<h2 className="text-center upper-case-text">
									<Trans>lang_key_repeat</Trans>
								</h2>
								<p className="text-center">Come back every quarter for new earning opportunities. Your customers will come back for brands that get the job done.</p>
							</Card>
						</Col>
					</Row>
					{/* Registration Hide */}
					{/* <div className="text-center mb-5">
						<a href="/register" className="btn btn-primary" ><Trans>lang_key_joinNow</Trans></a>
					</div> */}
				</Col>
			</Row>
		);
	}
}

export default ParticipateInstructions;
