import React from "react";
import { Container, Alert } from "react-bootstrap";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
const ExpiredCampaign = (props) => {
	const config = useSelector((state) => state.app.config);
	const history = useHistory();
	React.useEffect(() => {
		if (config.campaignState !== "expired") {
			history.push("/");
		}
	}, [config]);
	return (
        <Container>
			<h1 className="text-center border-bottom mt-5" style={{ color: "black", textTransform: "capitalize" }}>
				<Trans>404 Not Found</Trans>
			</h1>
		</Container>
	);
};

export default ExpiredCampaign;
