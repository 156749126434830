import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory, {
	PaginationProvider,
	PaginationListStandalone,
  } from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useTranslation } from "react-i18next";

const { SearchBar } = Search;
const TableReport = (props) => {
	const { t } = useTranslation();
	const searchTextboxEnable = props.searchTextboxEnable;
	const options = {
		paginationSize: 4,
		pageStartIndex: 1,
		alwaysShowAllBtns: true, // Always show next and previous button
		withFirstAndLast: false, // Hide the going to First and Last page button
		hideSizePerPage: true, // Hide the sizePerPage dropdown always
		//hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
		firstPageText: "First",
		prePageText: "Prev",
		nextPageText: "Next",
		lastPageText: "Last",
		// nextPageTitle: `${t("Next")} ${t("page")}`,
		// prePageTitle: `${t("Prev")} ${t("page")}`,
		// firstPageTitle: `${t("First")} ${t("page")}`,
		// lastPageTitle: `${t("Last")} ${t("page")}`,
		custom: true,
		showTotal: false,
		disablePageTitle: true,
		sizePerPageList: false,
		hideSizePerPage: true,
	};

	return (
		<ToolkitProvider
		data={props.data}
		columns={props.columns}
		keyField={props.keyField? props.keyField:"id"}
		search
		>
		{(props) => (
			<div className="table-report-div">
			{searchTextboxEnable ? (
				<SearchBar
				style={{ width: "100%" }}
				{...props.searchProps}
				placeholder={t("Search Product")}
				/>
			) : (
				""
			)}
			<PaginationProvider pagination={paginationFactory(options)}>
				{({ paginationProps, paginationTableProps }) => {
				return (
					<div>
					<BootstrapTable
						{...props.baseProps}
						{...paginationTableProps}
						wrapperClasses="table-responsive"
						rowClasses="text-nowrap"
					/>
						<div className="pagination-block">
							<PaginationListStandalone {...paginationProps} />
						</div>
					</div>
				);
				}}
			</PaginationProvider>
			</div>
		)}
		</ToolkitProvider>
	);
};
export default React.memo(TableReport);
