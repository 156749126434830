import React, { useState } from "react";
import { logoutAction } from "../redux/actions";
import { connect } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { Modal, Button, CloseButton } from "react-bootstrap";
import IsLoadingHOC from "./IsLoadingHOC";

const Logout = (props) => {
	const [show, setShow] = useState(false);
	const { t } = useTranslation();
	const handleClose = () => {
		setShow(false);
	};
	const handleShow = () => {
		setShow(true);
	};
	const handleLogout = () => {
		props.setLoading(true);
		setShow(false);
		props
			.logoutAction()
			.then((response) => {
				props.setLoading(false);
				window.location.href = "/";
			})
			.catch((ex) => {
				console.log("Error In API Call", ex);
				props.setLoading(false);
				window.location.href = "/";
			});
	};
	return (
		<>
			<button id="button-logout" onClick={handleShow} className="btn btn-profile">
			<span><img className="dd-item-icon" src="/public/assets/images/profile/007-log-out.png" alt={t("Logout")} /></span>
			<Trans>Logout</Trans>
			</button>
			<Modal show={show}>
				{/* <Modal.Header>
					<Modal.Title>
						<Trans>Confirm Logout</Trans>
					</Modal.Title>
					<CloseButton onClick={handleClose} />
				</Modal.Header> */}
				<Modal.Body className="justify-content-center text-center">
					{/* <Trans>Are you sure you want to logout now</Trans>? */}
					<p className="mb-5">Don't leave yet!</p>

					<button className="btn btn-modal" onClick={handleClose}>
						{/* <Trans>Close</Trans> */}
						SURE, I'LL STAY
					</button>
					<div className="text-center mt-4">
						<button className="btn btn-modal" onClick={handleLogout}>
							{/* <Trans>Logout</Trans> */}
							CONTINUE TO LOGOUT
						</button>
					</div>
				</Modal.Body>
				
			</Modal>
		</>
	);
};
export default connect(null, { logoutAction })(IsLoadingHOC(Logout));
